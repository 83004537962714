export function buildUrl(url, params) {
    // NOTE: This won't work in IE
    const newUrl = new URL(url);
    newUrl.search = new URLSearchParams(params);
    return newUrl;
}


export function post(url, body, params=null) {
    return fetch(
        url,
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
            ...params
        }
    );
}

export function get(url, body) {
    return fetch(
        url,
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }
    );
}
