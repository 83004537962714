import {useRouter} from "next/router";
import "/css/tailwind.css";
import 'video.js/dist/video-js.css';
import '/css/videojs.css';
import {AppProvider} from "../context/AppContext";
import {FilterEventProvider} from "../context/filter/FilterContext";
import "swiper/css";
import 'swiper/css/pagination';
import '/css/styles.css';
import "/css/slick.css";
import "/css/widget.css";
import "/css/bubble.css";
import "slick-carousel/slick/slick-theme.css";

function MyApp({Component, pageProps}) {
    const router = useRouter();

    return (
        <AppProvider {...pageProps}>
            <FilterEventProvider>
                {/* <AnimatePresence exitBeforeEnter> */}
                <Component {...pageProps} key={router.route}/>
                {/* </AnimatePresence> */}
            </FilterEventProvider>
        </AppProvider>
    );
}

export default MyApp;
