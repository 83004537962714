import { useEffect, useState } from "react";
const LOGIN_KEY = "redemption";

// useEffect exhaustive dependencies
// SSR === Client-side hydration
// SVG Component

export default function useTicketLogin({ addToken }) {
    const [activeShow, setActiveShow] = useState(null);
    const [activeRedemption, setActiveRedemption] = useState(null);
    const [activeRating, setActiveRating] = useState(null);

    function setActiveData(data) {
        const { show, redemption, rating } = data;
        setActiveShow(show);
        setActiveRedemption(redemption);
        setActiveRating(rating);
    }

    function resetActiveData() {
        setActiveShow(null);
        setActiveRedemption(null);
        setActiveRating(null);
    }

    useEffect(() => {
        let initialActiveShow = null;
        let redemption = localStorage.getItem(LOGIN_KEY);
        if (redemption) {
            try {
                // TODO: need to fetch the data again here for redemption to keep things fresh :)
                let data = JSON.parse(redemption);

                // Refresh Data from server.
                const url = `/api/refresh?show_id=${data.show.id}&device_token=${data.redemption.device_token}`;
                let resp = fetch(url, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                })
                    .then(async function (resp) {
                        if (resp.status != 200) {
                            let err = await resp.json();
                            throw err.error;
                        }

                        // Need to get this from API
                        let d = await resp.json();

                        // Store data, and set active show.
                        login(d);
                    })
                    .catch((err) => {
                        // TODO: Check what we should do in this case, possible nothing for now, we keep the old data.
                        console.log("ERROR", err);
                    });
            } catch (err) {
                // If we cannot parse redemption, we simply clear it.
                console.log("ERROR CLEARING", err);
                localStorage.removeItem(LOGIN_KEY);
            }
        } else {
            console.log("NO REDEMPTION");
        }
    }, []);

    function login(data) {
        localStorage.setItem(LOGIN_KEY, JSON.stringify(data));
        addToken(data.redemption.device_token);
        setActiveData(data);
    }

    function logout() {
        localStorage.removeItem(LOGIN_KEY);
        // TODO: Remove this
        localStorage.removeItem("tokens");

        resetActiveData();
    }

    return [
        activeShow,
        setActiveShow,
        activeRedemption,
        activeRating,
        setActiveRating,
        login,
        logout
    ];
}
