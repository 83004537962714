import {CLEAR_STATE, SEARCH_FOR_EVENT,} from "./filter-actions";

export const initialState = {
    searchQuery: '',
}

export const filterReducer = (state, action) => {
    switch (action.type) {
        case SEARCH_FOR_EVENT:
            return {
                ...state,
                searchQuery: action.payload
            };
        case CLEAR_STATE:
            return {...initialState};

        default:
            return state;

    }
}

export const stateInitializer = () => {
    return {
        ...initialState,
    };
};
