import { useEffect, useState } from "react";
const STORAGE_KEY = 'tokens';
import { get } from '/api_utils';
import { uniq } from 'lodash';


export default function useTokens() {
    const [products, setProducts] = useState({});
    const [tokens, setTokens] = useState([]);

    // On first load, we get the tokens from the storage.
    useEffect(() => {
        try {
            let tokensData = localStorage.getItem(STORAGE_KEY);
            // if (!tokensData) {
            //     tokensData = JSON.stringify(["xiCNKlmbhVWsqvCglYmM", '1234']);
            // }
            if (tokensData) {
                setTokens(JSON.parse(tokensData) || []);
            }
        } catch(err) {
            localStorage.removeItem(STORAGE_KEY);
        }
    }, []);

    // On every change of tokens, we refresh the product list.
    useEffect(() => {
        const fetchData = async () => {
            if (!tokens.length) {
                return;
            }
            let resp = await get(
                `/api/products?device_token=${tokens.join('&device_token=')}`
            );
            if (resp.status === 200) {
                let products = await resp.json();
                // Store procuts with id as key for faster lookup.
                setProducts(Object.fromEntries(products.map(product => [product.id, product])));
            }
        };
        fetchData();
    }, [tokens]);

    function addToken(token) {
        let newTokens = uniq([...tokens, token]);
        setTokens(newTokens);
        localStorage.setItem(STORAGE_KEY, JSON.stringify(newTokens));
    }

    return [tokens, addToken, products];
}
