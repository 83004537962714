import {createContext, memo, useReducer} from 'react';
import {filterReducer, initialState, stateInitializer} from "./FilterReducer";

const FilterEventContext = createContext({
    state: {},
    dispatch: () => {
    },
});


const FilterEventProvider = memo(({children}) => {

    const [state, dispatch] = useReducer(
        filterReducer,
        initialState,
        () => stateInitializer(initialState)
    );

    return (
        <FilterEventContext.Provider
            value={{
                state,
                dispatch,
            }}>
            {children}
        </FilterEventContext.Provider>
    );
});

FilterEventProvider.displayName = 'FilterEventContext.Provider';

export {FilterEventContext as default, FilterEventProvider};