import {createContext, useState } from "react";
import useTicketLogin from '/hooks/useTicketLogin';
import useTokens from '/hooks/useTokens';
const AppContext = createContext({});


const AppProvider = ({children, productions, selectedProduction, selectedShow}) => {
    // Only run on client.
    const [data, setData] = useState([]);
    const [agendaVisible, setAgendaVisible] = useState(false);
    const [showForValidation, setShowForValidation] = useState(null);
    const [tokens, addToken, products] = useTokens();
    const [activeShow, setActiveShow, activeRedemption, activeRating, setActiveRating, ticketLogin, ticketLogout] = useTicketLogin({ addToken });

    return (
        <AppContext.Provider value={{
            data,
            productions,
            setData,
            activeShow,
            setActiveShow,
            activeRedemption,
            activeRating,
            setActiveRating,
            ticketLogin,
            ticketLogout,
            agendaVisible,
            setAgendaVisible,
            showForValidation,
            setShowForValidation,
            selectedProduction,
            selectedShow,
            tokens,addToken, products
        }}>
            {children}
        </AppContext.Provider>
    );
};
export {AppContext as default, AppProvider};

